import React, { useCallback, useContext, useMemo, useState } from 'react'
import { BulkCertifyParam, certificationApi } from '../../../utils/api/certification'
import { useQuery } from 'react-query'
import { map as bluebirdMap } from "bluebird";
import { FormContext, FormContextProvider, FormContextValue, FormSchemaFields } from '@sw-sw/lib-form'
import { FormModal } from '../../Shared/form'
import { CertFormSchema, CertSchemaField, CertificationIndexResponse, SignatureGroupType, fieldKeyCodec, getCertFormDateData, getCertFormDateSchema, getCertFormUserData, getCertFormUserSchema } from '@sw-sw/lib-certification';
import { find, get } from 'lodash';
import { getInspectionTypeLabel } from '../../../utils';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import InspectionCertificationStatement from './InspectionCertificationStatement'
import SignatureCheckbox from './SignatureCheckbox';
import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';


function extractSchemaData(
    formData: Record<string, any>,
    state: { id: number; certState: CertificationIndexResponse }[],
  ): BulkCertifyParam {
    return state.reduce<BulkCertifyParam>((data, { id, certState }) => {
      certState.signatureGroups[SignatureGroupType.inspection].forEach(
        ({ type: lineType }) => {
          const key = fieldKeyCodec.encode(lineType, CertSchemaField.date, id);
          const date = get(formData, key, null);
  
          if (date) {
            data.push({ id, date, lineType });
          }
        },
      );
  
      return data;
    }, []);
}

function getLabelSchema(label: string, isHeading?: boolean) {
    return {
      label,
      controlType: UIControlType.plainText,
      className: isHeading ? "modal-h3" : undefined,
    };
}

const BulkInspectionCertifyModal = ({
    inspections,
    hideModal,
    handleSaveAnimation,
}:{
    inspections: any,
    hideModal: () => void;
    handleSaveAnimation: () => void;
}) => {
  const [checked, setChecked] = useState(false);
  const [inspectionName, setInspectionName] = useState<any[]>([])
  const appContext = useContext(AppContext);
  const user = appContext.get("user");
  const { fetchFuncForInspectionCert } = useContext(DashboardContext);
  const [error, setError] = useState(false)
  const inspectionIds = inspections.map((_:any) => _.id);
  const [selectedLabel, setSelectedLabel] = useState<string>()
  const [allLabels, setAllLabels] = useState<string[]>([])
  const query = useQuery({
    queryKey: ["InspectionCert", ...inspectionIds],
    queryFn: () =>
      bluebirdMap(
        inspectionIds,
        async (id:any) => {
          return {
            id,
            certState: await certificationApi.index(id),
          };
        },
        { concurrency: 4 },
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleSubmit = useCallback(
    async (formData, formContext: FormContextValue) => {
      let updatedData = query.data

      if (!checked) {
          formContext.setError(
              "Please complete the Electronic Authorization Signature",
          );

          return;
      }

      if(selectedLabel && updatedData){
        updatedData = updatedData.map((element) => {
          element.certState.signatureGroups.compliance = element.certState.signatureGroups.compliance.filter((ele:any) => {
            if(selectedLabel === "ALL Signatures"){
              return true
            }

            if(selectedLabel === "NO Label"){
              return (ele.label === null || ele.label === "")
            }

            return ele.label === selectedLabel
          })

          return element
        })
      }

      return certificationApi
      .bulkInspectionCertify(extractSchemaData(formData, updatedData || []))
      .then(() => {
      //   onSubmit();
        fetchFuncForInspectionCert()
        hideModal();
        handleSaveAnimation();
      });
    },
    [checked, query.data],
  );

  const schema = useMemo(() => {
    const sch: CertFormSchema = {};
  
    if (query.data && query.data.length) {
      const firstGroups = query.data[0].certState.signatureGroups;
      const firstLine =
        firstGroups.inspection.length > 0
          ? firstGroups.inspection[0]
          : firstGroups.compliance.length > 0
            ? firstGroups.compliance[0]
            : undefined;

      if (firstLine !== undefined) {
        // name and position fields (first inspection & signature line only)
        Object.assign(
          sch,
          getCertFormUserSchema({
            encodeKey: (type, field) =>
              fieldKeyCodec.encode(type, field, inspectionIds[0]),
            line: firstLine,
          }),
        );
      }

      // date field, each inspection, each line
      query.data.forEach(({ certState, id }) => {
        const insp = find(inspections, { id });

        if (!certState.groupOptions[
          SignatureGroupType.inspection
        ].enabled) {
          setError(true)
          const abc: any[] = inspectionName

          insp && abc.push(`${insp.created_date} | ${insp.type}`)
          setInspectionName(abc)
        }

        let lines = certState.signatureGroups.inspection.filter(line => {
          return (
            find(certState.userSignatures.inspection, {
              lineType: line.type,
            }) === undefined
          );
        });

        //adding all labels in the state for filtering based on Label
        lines.forEach(ele => {
          let displayLabel = ele.label

          if(ele.label === null || ele.label === ""){
            displayLabel = "NO Label"
          }

          setAllLabels(prevState => {
            if(prevState.includes(`${displayLabel}`)){
              return [...prevState]
            }
            else{
              return [...prevState, `${displayLabel}`]
            }
          })
        })
        
        if(selectedLabel){
          lines = lines.filter(ele => {
            if(selectedLabel === "ALL Signatures"){
              return true
            }

            if(selectedLabel === "NO Label"){
              return (ele.label === null || ele.label === "")
            }

            return ele.label === selectedLabel
          })
        }

        if (insp && lines.length) {
          Object.assign(sch, {
            [`${id}-label`]: getLabelSchema(getInspectionTypeLabel(insp, false), true),
          });

          lines.forEach(line => {
            const dateSchema = getCertFormDateSchema({
              enabled: true,
              line,
              minDate: new Date(certState.minCertificationDate),
              encodeKey: (type, field) => fieldKeyCodec.encode(type, field, id),
              label: line.label ? `${line.label} Date` : "Signature Date",
            });
            const [dateFieldKey] = Object.keys(dateSchema);

            Object.assign(dateSchema[dateFieldKey].style, {
              // flex: "0 1 20rem",
              flex: "0 1 100%",
              // minWidth: "20rem",
              // display: "flex",
              // flexFlow: "row nowrap",
            });

            Object.assign(dateSchema[dateFieldKey], {
              isClearable: true,
            });

            Object.assign(sch, dateSchema);
          });
        }
      });
    }

    return sch;
  }, [query.data, selectedLabel]);

  const inspectionCertDefaultDate = (certState:any) => {
    if(certState.inspectionDOI){
      const doi = new Date(certState.inspectionDOI)
      const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000)

      return doiWithTz
    }
    else{
      return new Date()
    }
  }

  const initialValue = useMemo(() => {
    const data = {};

    if (query.data) {
      query.data.forEach(({ certState, id }) => {    
        if (
        //   certState.groupOptions.inspection.enabled &&
          certState.signatureGroups.inspection.length
        ) {
          let lines = certState.signatureGroups.inspection.filter(line => {
          return (
            find(certState.userSignatures.inspection, {
            lineType: line.type,
            }) === undefined
          );
          });

          lines.forEach(line => {
          Object.assign(
            data,
            getCertFormDateData({
              encodeKey: (type: string, field: CertSchemaField) =>
                fieldKeyCodec.encode(type, field, id),
              lineType: line.type,
              value: (inspectionCertDefaultDate(certState)),
            }),
            getCertFormUserData({
              encodeKey: (type: string, field: CertSchemaField) =>
                fieldKeyCodec.encode(type, field, id),
              lineType: line.type,
              value: {
                  name: user.name,
                  role: user.position || user.roleName,
              },
            }),
          )
        })
      }
    })
  }

  return data;
  }, [query.data]);
    
    return (
        <FormContextProvider initialValue={initialValue}>
            <FormContext.Consumer>
                {formContext => (
                <FormModal
                    modalProps={{
                        title: "Sign Inspection Certification",
                    //   subTitle: (!fromWidget) ? `Compliance Certification for ${
                    //     (projectStore as any).projectName
                    //   }` : "",
                        submitBtnText: "Certify",
                        isExtraWide: true,
                    }}
                    onCancel={hideModal}
                    onSubmit={formData => {
                        return handleSubmit(formData, formContext)
                    }}
                >
                    <section className="bulk-cert-modal">
                        <div className="bulk-cert-modal__filter-signature">
                          <span>Filter Signature :</span>
                          <select 
                            name="label-type"
                            onChange={(event) => {
                                setSelectedLabel(event.target.value)
                            }}
                          >
                            <option value="-10" hidden>Select Label</option>
                            <option value="ALL Signatures">ALL Signatures</option>
                            {
                              allLabels.length ? allLabels.map((ele) => {
                                return(
                                  <option value={ele}>{ele}</option>
                                )
                              }):
                              <option value="-10" disabled>No Labels Found</option>
                            }
                          </select>
                        </div>

                        <div className="bulk-cert-modal__form-schema-fields">
                          <FormSchemaFields
                          schema={schema}
                          onChange={formContext.set}
                          formData={formContext.value}
                          />
                        </div>

                        {error ?
                          <p className="error-text">
                            {
                              inspectionName.map(insp => (
                                <div>
                                  <strong>{insp}</strong>
                                </div>
                              ))
                            }
                            ** The following inspection cannot be signed. Check project for more info.
                          </p>
                          :
                          null
                        }

                        {Array.isArray(query.data) ?
                        <>
                            <InspectionCertificationStatement
                                attestations={
                                    query.data[0].certState.attestations[SignatureGroupType.inspection]
                                }
                            />
                            <SignatureCheckbox
                                enabled={
                                    query.data[0].certState.groupOptions[
                                        SignatureGroupType.inspection
                                    ].enabled
                                }
                                errors={
                                    query.data[0].certState.groupOptions[
                                        SignatureGroupType.inspection
                                    ].errors
                                }
                                checked={checked}
                                onSubmit={async () => {
                                    formContext.setError(null);
                                    setChecked(!checked);
                                }}
                            />
                        </>
                        : <></>
                        }
                    </section>
                </FormModal>
                )}
            </FormContext.Consumer>
        </FormContextProvider>
    );
}

export default BulkInspectionCertifyModal