import { default as React, useContext, useMemo } from "react";
import AppContext from "../../../contexts/AppContext";
import InspectionCertificationContext, {
  InspectionCertificationContextProvider,
} from "../../../contexts/InspectionCertificationContext";
import { InspectionContext } from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import RolesContext from "../../../contexts/RolesContext";
import {
  CertificationUI,
  getcDOTObservationTemplates,
} from "./CertificationUI";
import NotesForm from "./NotesForm";

const InspectionCertification = () => {
  const projectContext: any = useContext(ProjectContext);
  const inspectionContext = useContext(InspectionContext);
  const appContext = useContext(AppContext);
  const templateName = useMemo(
    () => (projectContext.template ? projectContext.template.name : null),
    [projectContext.template],
  );
  const isCcr =
    (appContext.get("constants.ccrTemplates") as string[]).includes(
      templateName,
    ) && inspectionContext.inspection.project.isCcrProject;
  const isPe = (appContext.get("constants.peTemplates") as string[]).includes(
    templateName,
  );

  const inspectorMinDate = () => {
    const date = new Date(inspectionContext.inspection.created_date);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + offset);
  };

  const permCheck = useContext(RolesContext).userHasPermission;

  const readOnly = () => {
    if (permCheck("all", "Sign Off") || permCheck("all", "Certify")) {
      return false
    }
    return true
  }

  return (
    <InspectionCertificationContextProvider>
      <InspectionCertificationContext.Consumer>
        {certContext => (
          <>
            <CertificationUI
              Acknowledgement={certContext.state.attestations.acknowledgement.length ? true : false}
              signatureGroups={certContext.state.signatureGroups}
              attestationGroups={certContext.state.attestations}
              isCcr={isCcr}
              isPe={isPe}
              inspectorMinCertificationDate={inspectorMinDate()}
              minCertificationDate={
                new Date(certContext.state.minCertificationDate)
              }
              onSave={certContext.onSave}
              userDataGroups={certContext.state.userSignatures}
              optionGroups={certContext.state.groupOptions}
              showCertificate={inspectionContext.inspection.inspection_template.has_acknowledgement || certContext.state.userSignatures.acknowledgement.length ? true : false}
              showComplianceCert={inspectionContext.inspection.inspection_template.has_compliance_cert}
              ackName={projectContext.template && projectContext.template.name === 'CDOT' ? "Inspection Certification (optional)" : "Acknowledgement"}
              readOnly={readOnly()}
            />
            <NotesForm
              inspectionId={inspectionContext.inspection.id}
              handleSuccess={() => certContext.flashSuccess()}
              templates={
                templateName === "CDOT"
                  ? getcDOTObservationTemplates(projectContext.clientName)
                  : undefined
              }
            />
          </>
        )}
      </InspectionCertificationContext.Consumer>
    </InspectionCertificationContextProvider>
  );
};

export default InspectionCertification;
