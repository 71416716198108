// return the value of 'routine inspection' for cdot templates
export const isCdotRoutineInspectionType = (value: string) => {
  if (value) {
    if (!JSON.parse(value)[0] && !JSON.parse(value)[1]) {
      return true;
    } else {
      return JSON.parse(value)[0];
    }
  }

  return false
};
