import { find, findIndex, get, toNumber } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  InspectionContext,
  isInspectionSigned,
} from '../../contexts/InspectionContext';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import { getDownloadUrl } from '../../utils/api/upload';
import MapEditor from '../MapEditor';
import { MapLoadingError } from '../MapEditor/MapLoadingError';
import { MapLoadingSpinner } from '../MapEditor/MapLoadingSpinner';
import { useDocType } from '../Projects/useDocType';
import { useInspectionUploads } from '../Projects/useInspectionUploads';
import { useSiteMaps } from '../Projects/useSiteMaps';
import {
  ProjectPermissionContextProvider,
  useProjectPermission,
} from '../../contexts/ProjectPermissionContext';
import { useProject } from '../../hooks/project';
import clientApi from '../../utils/api/client';
import { useProjectInspections } from '../../hooks/inspection';
import { withProjectContext } from '../../hoc/withProjectContext';
import { useMediaQuery } from "react-responsive"

export const MapEditorRouteContext = React.createContext();

/**
 * extract data from route, for map editor
 * project, map, notes, inspection findings, control measures
 *
 * @todo inspection findings dataSource
 */
function MapEditorRoute({ history, match, location }) {
  const { projectId, mapId } = match.params;
  const inspectionContext = useContext(InspectionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const projectPermissionContext = useProjectPermission(false);
  const isTabletOrLess = useMediaQuery({ maxWidth: 768 })
  const [mapMobileView, setMapMobileView] = useState(isTabletOrLess ? true : false)

  const mapImgSrc = useRef(getDownloadUrl(mapId));

  /** @todo initial state from optional route props */
  const [selectedInspectionIndex, setSelectedInspectionIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [client, setClient] = useState(null);

  const projectQuery = useProject(projectId, setError);
  const docTypeId = useDocType(projectId, 'Active Site Maps', setError);
  const maps = useSiteMaps(projectId, docTypeId, setError);
  const inspectionsQuery = useProjectInspections(projectId, setError, undefined, undefined, true);
  const inspections = inspectionsQuery.data || [];
  const inspectionUploads = useInspectionUploads(
    projectId,
    selectedInspectionIndex !== null
      ? !inspectionsQuery.isFetching && inspections.length ? inspections[selectedInspectionIndex].id : null
      : null,
    setError,
  );
  const [inspectId, setInspectId] = useState([])

  useEffect(() => {
    setInspectId((prevId) => [...prevId, inspectionContext.inspectionId])
  }, [inspectionContext.inspectionId])
  
  useEffect(() => {
    if (inspectionsQuery.isFetched && inspections.length) {
      const i = location.state
        ? findIndex(inspections, {
          id: toNumber(location.state.inspectionId),
        })
        : -1;

      setSelectedInspectionIndex(i > -1 ? i : 0);
    }
  }, [inspectionsQuery.isFetched, inspections]);

  useEffect(() => {
    if (selectedInspectionIndex === null) {
      inspectionContext.setInspectionLoaded(false);
      inspectionContext.resetInspection();
    } else {
      inspectionContext.updateInspection(inspections[selectedInspectionIndex]);
    }
  }, [selectedInspectionIndex]);

  /** Required variables before loading is complete */
  useEffect(() => {
    if (maps && projectQuery.data && inspections) {
      setLoading(false);
    }
  }, [maps, projectQuery.data, inspections]);

  useEffect(() => {
    if (projectQuery.data && projectQuery.data.archivedAt) {
      projectPermissionContext.setReadOnly(true);
    }

    if (projectQuery.data && projectQuery.data.client_id) {
      clientApi
        .show(projectQuery.data.client_id)
        .then((data) => setClient(data));
    }
  }, [projectQuery.data]);

  //

  if (error) {
    return <MapLoadingError projectId={Number(projectId)} content={error} />;
  }

  if (loading === true || !client) {
    return <MapLoadingSpinner />;
  }

  const projectUpload = find(maps, {
    upload: {
      GUID: mapId,
    },
  });

  if (!projectUpload) {
    setError('Missing required variables: projectUpload');

    return null;
  }

  if (
    !appDivisionContext.appDivisionId 
  ) {
    setError('Missing required variables: divisionId');
    return null;
  }

  /**
   * Define data sources
   *
   * @important {positionableType} must be unique!
   *
   * @see {PositionableDataSource}
   */
  const inspectionUpload = find(inspectionUploads || [], {
    project_upload_id: projectUpload.id,
  });
  const dataSources = [];

  dataSources.push(
    {
      positionableType: 'control_measure',
      positionableContext: 'legend_items',
      targetType: 'project_uploads',
      targetId: projectUpload.id,
      order: 2,
      positionableIndexArguments: [projectQuery.data.id,appDivisionContext.appDivisionId],
      sourceModelFormModalProps: {
        isSmall: false,
      },
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
      enableSearch: true,
      startInspectionId: inspections[selectedInspectionIndex]
        ? inspections[selectedInspectionIndex].id
        : -1,
      endInspectionId: inspections[selectedInspectionIndex + 1]
        ? inspections[selectedInspectionIndex + 1].id
        : null,
    },
    {
      positionableType: 'pollutant',
      positionableContext: 'legend_items',
      targetType: 'project_uploads',
      targetId: projectUpload.id,
      order: 3,
      positionableIndexArguments: [projectQuery.data.id,appDivisionContext.appDivisionId],
      sourceModelFormModalProps: {
        isSmall: false,
      },
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
      enableSearch: true,
      startInspectionId: inspections[selectedInspectionIndex]
        ? inspections[selectedInspectionIndex].id
        : -1,
      endInspectionId: inspections[selectedInspectionIndex + 1]
        ? inspections[selectedInspectionIndex + 1].id
        : null,
    },
    {
      positionableType: 'commentables',
      positionableTypeLabel: 'Notes',
      targetType: 'project_uploads',
      targetId: projectUpload.id,
      positionableIndexArguments: ['project_uploads', projectUpload.id],
      order: 4,
      maxInstances: 1,
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
      startInspectionId: inspections[selectedInspectionIndex]
        ? inspections[selectedInspectionIndex].id
        : -1,
      endInspectionId: inspections[selectedInspectionIndex + 1]
        ? inspections[selectedInspectionIndex + 1].id
        : null,
    },
  );

  if (inspectionUpload) {
    dataSources.push({
      positionableType: 'findings',
      targetType: 'project_inspection_uploads',
      targetId: inspectionUpload ? inspectionUpload.id : null,
      positionableIndexArguments: (formData = null) => {
        return [
          // either the finding id (for updating) or the inspection id (for creation)
          formData && formData.id
            ? formData.id
            : inspectionUpload.inspection_id,
        ];
      },
      order: 1,
      startInspectionId: inspectionUpload
        ? inspectionUpload.inspection_id
        : null,
      endInspectionId: inspections[selectedInspectionIndex + 1]
        ? inspections[selectedInspectionIndex + 1].id
        : null,
      sourceModelFormProps: {
        readOnly:
          projectPermissionContext.readOnly ||
          isInspectionSigned(inspections[selectedInspectionIndex]),
        templateName: get(
          inspections[selectedInspectionIndex],
          'inspection_template.name',
        ),
        enableEditComments: true,
        defaultObservation: client.default_finding_observation,
      }
    },
    );
  }

  const geturlstring = history.location.search;
  const testUrlStr = geturlstring.match('inspection');
console.log("inspe>>", inspections);

  return (
    <ProjectPermissionContextProvider>
      <MapEditorRouteContext.Provider
        value={{
          dataSources,
          projectName: projectQuery.data.name,
          projectId: projectQuery.data.id,
          mapName: projectUpload.upload.name,
          updatedAt: new Date(
            Date.parse(projectUpload.updatedAt),
          ).toLocaleString(),
          projectTemplate: projectQuery.data.template,

          inspections,
          selectedInspection:
            selectedInspectionIndex === null
              ? null
              : inspections[selectedInspectionIndex],

          setSelectedInspection: (id) => {
            const i = findIndex(inspections, {
              id: toNumber(id),
            });

            if (i > -1) {
              setSelectedInspectionIndex(i);
            }
          },
          goBack: () => {
            const isPublic = window.location.pathname.startsWith("/public")
            const initialInspectionId = inspectId.splice(1, 1)

            history.push(
              (isPublic ? appDivisionContext.getPublicPath(
                testUrlStr === null
                  ? `/projects/${projectId}/active-site-map`
                  : `/inspection/${initialInspectionId}/map`,
              ) : appDivisionContext.getPath(
                testUrlStr === null
                  ? `/projects/${projectId}/active-site-map`
                  : `/inspection/${initialInspectionId}/map`,
              ))
            );
          },
          mapMobileView: mapMobileView,
          setMapMobileView: setMapMobileView
        }}
      >
        <MapEditor
          dataSources={dataSources}
          mapImgSrc={mapImgSrc.current}
          mapId={mapId}
          divisionId={appDivisionContext.appDivisionId}
          projectId={Number(projectId)}
        />
      </MapEditorRouteContext.Provider>
    </ProjectPermissionContextProvider>
  );
}

export default withProjectContext(MapEditorRoute);
