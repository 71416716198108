import { Button } from "@sw-sw/lib-ui";
import { kebabCase } from "lodash";
import React, { PropsWithChildren, useContext, useState } from "react";
import RolesContext from "../../../contexts/RolesContext";
import { ActionButtonsWithScrollFix } from "../../Shared/ActionButtons";
import InspectionReopenConfirmationModal from "./InspectionReopenConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { InspectionContext } from '../../../contexts/InspectionContext';

/** Provides action buttons for any template (legacy or user-generated) */
export const AnyTemplateLayout: React.FC<
  PropsWithChildren<{
    templateName: string;
    onSave: () => Promise<void>;
    busy?: boolean;
    error?: string;
    isReadOnly?: boolean;
    isPublic?:boolean;
    onSaveAndContinue?: Function;
    isInspectionReadOnly?: boolean;
  }>
> = ({
  children,
  templateName,
  onSave,
  error,
  busy = false,
  isReadOnly = false,
  isPublic = false,
  isInspectionReadOnly = false,
  onSaveAndContinue
}) => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const inspection = useContext(InspectionContext)
  const [showReopenConfirmationModal, setShowReopenConfirmationModal] =
    useState<boolean>(false);

    const onclick = () => {
      setShowReopenConfirmationModal(true)
      inspection.setIsInspectionReadOnly(false)
    }

  
  return (
    <section
      className={`inspection-questions inspection-questions-${kebabCase(
        templateName,
      )}`}
    >
      {!isPublic && !isReadOnly && !isInspectionReadOnly && (
        <ActionButtonsWithScrollFix
          primary='Save'
          onClick={onSave}
          busy={busy}
          error={error}
          spacer={false}
          saveAndContinue={true}
          onSaveAndContinue={onSaveAndContinue}
        />
      )}

      {!isPublic &&
        isReadOnly &&
        permCheck('all', 'reopenInspection') &&
        !isInspectionReadOnly && (
          <div className='action-buttons'>
            <Button primary onClick={() => onclick()}>
              <React.Fragment>
                <FontAwesomeIcon icon={faPencilAlt} /> Edit Inspection
              </React.Fragment>
            </Button>
          </div>
        )}
      {isInspectionReadOnly &&
        !isPublic &&
        permCheck('update', 'Inspection') && (
          <div className='action-buttons'>
            <Button
              primary
              onClick={() => inspection.setIsInspectionReadOnly(false)}
            >
              <React.Fragment>
                <FontAwesomeIcon icon={faPencilAlt} /> Edit Inspection
              </React.Fragment>
            </Button>
          </div>
        )}
      {children}
      {showReopenConfirmationModal && (
        <InspectionReopenConfirmationModal
          onClose={() => {
            setShowReopenConfirmationModal(false);
          }}
        />
      )}
    </section>
  );
};
