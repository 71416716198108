import { useEffect, useState, useContext } from 'react';
import { QueryObserverOptions, useQuery } from 'react-query';
import { ProjectInspection } from '../utils/api/projectInspection';
import inspectionsCertificationDue from '../utils/api/inspectionsCertificationDue';
import { InspectionFindingCount } from '../utils/findingCount';
import { customDashboard } from '../utils/api/dashboard';
import AppDivisionContext from '../contexts/AppDivisionContext';

export type InspectionWithFindingCount = ProjectInspection & {
  findingCount: InspectionFindingCount;
};

// get all certification due list
export function useInspectionsCertificationDue(
  queryParams: any,
  divisionId: number,
  loadData: boolean,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
) {

  const query = useQuery<any[]>({
    queryKey: ['Inspections', 'CertificationDue', 'All'],
    queryFn: async () => {
      const data = loadData ? await inspectionsCertificationDue.index(queryParams, divisionId) : [];

      return data;
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [queryParams, divisionId, loadData]);

  return query;
}

// inspection certification
export function useFetchListForInspectionCert() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForInpectionCert, setProjectsListForInpectionCert] = useState([]);
  const [isLoadingForInspectionCert, setIsLoadingForInspectionCert] = useState(true);
  const [ searchQueryForInspectionCert, setSearchQueryForInspectionCert ] = useState({})

  const fetchFuncForInspectionCert = () => {
    setIsLoadingForInspectionCert(true);
    customDashboard
    .fetchWithParams(`/divisions/${appDivisionId}/getInspectionCert`, searchQueryForInspectionCert)
    .then((res) => {
      res.data ? setProjectsListForInpectionCert(res.data) : setProjectsListForInpectionCert([]);
      setIsLoadingForInspectionCert(false)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchFuncForInspectionCert()
  }, [appDivisionId, searchQueryForInspectionCert]);

  return [
    isLoadingForInspectionCert,
    projectsListForInpectionCert,
    setProjectsListForInpectionCert,
    setSearchQueryForInspectionCert,
    fetchFuncForInspectionCert
  ];
}

// acknowledgement certification
export function useFetchListForAcknowledgementCert() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForAcknowledgementCert, setProjectsListForAcknowledgementCert] = useState<any>([]);
  const [isLoadingForAcknowledgementCert, setIsLoadingForAcknowledgementCert] = useState(true);
  const [ searchQueryForAcknowledgementCert, setSearchQueryForAcknowledgementCert ] = useState({})

  const fetchFuncForAcknowledgementCert = () => {
    setIsLoadingForAcknowledgementCert(true);
    customDashboard
    .fetchWithParams(`/divisions/${appDivisionId}/getAcknowledgementCert`, searchQueryForAcknowledgementCert)
    .then((res) => {
      res.data ? setProjectsListForAcknowledgementCert(res.data) : setProjectsListForAcknowledgementCert([])
      setIsLoadingForAcknowledgementCert(false)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchFuncForAcknowledgementCert()
  }, [appDivisionId, searchQueryForAcknowledgementCert]);

  return [
    isLoadingForAcknowledgementCert,
    projectsListForAcknowledgementCert,
    setProjectsListForAcknowledgementCert,
    setSearchQueryForAcknowledgementCert,
    fetchFuncForAcknowledgementCert
  ];
}


// open Findings widget
export function useOpenFindingsData() {
  const [displayStatsForOF, setDisplayStatsForOF] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForOF, setAllProjectsForOF] = useState([]);
  const [isLoadingForOF, setIsLoadingForOF] = useState(true);

  const fetchStatsFuncForOF = () => {
    customDashboard
    .fetch(`/divisions/${appDivisionId}/openFindings/getOpenFindingStats`)
    .then((res) => {
      setAllProjectsForOF(res.data.statistics);
      setIsLoadingForOF(false);
      setDisplayStatsForOF(res.data.statistics);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForOF()    
  }, [appDivisionId]);

  return [
    displayStatsForOF,
    allProjectsForOF,
    isLoadingForOF,
    setDisplayStatsForOF,
    setIsLoadingForOF,
    fetchStatsFuncForOF
  ];
}

export function useFetchAllFindingTypes() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForOF, setFindingTypeListForOF] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/openFindings/fetchFindingTypesUsed`)
      .then((res) => {
        setFindingTypeListForOF(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [findingTypeListForOF];
}

// team management widget
export function useTeamManagement() {
  const [teamManagementData, setTeamManagementData] = useState(null);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [isLoadingForTM, setIsLoadingForTM] = useState(true);

  const getTeamManagementData = () => {
    setIsLoadingForTM(true);
    customDashboard
      .fetch(`/divisions/${appDivisionId}/getTeamManagementData`)
      .then((res) => {
        setTeamManagementData(res.data);
        setIsLoadingForTM(false);
      })
      .catch((error) => {
        setIsLoadingForTM(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!appDivisionId) return;
    getTeamManagementData();
  }, [appDivisionId]);

  return [
    getTeamManagementData,
    teamManagementData,
    isLoadingForTM,
    setIsLoadingForTM,
  ];
}

// My Projects Widget

export function useMyProjectsData() {
  const [displayStatsForMP, setDisplayStatsForMP] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForMP, setAllProjectsForMP] = useState([]);
  const [isLoadingForMP, setIsLoadingForMP] = useState(true);

  const fetchStatsFuncForMP = () => {
    setIsLoadingForMP(true);
    customDashboard
    .fetch(`/divisions/${appDivisionId}/myProjectsList/getMyProjectsListStats`)
    .then((res) => {
      setAllProjectsForMP(res);
      setIsLoadingForMP(false);
      setDisplayStatsForMP(res);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForMP()
  }, [appDivisionId]);

  return [
    displayStatsForMP,
    allProjectsForMP,
    isLoadingForMP,
    setDisplayStatsForMP,
    setIsLoadingForMP,
    fetchStatsFuncForMP
  ];
}

  //Inspections Due
export function useInspectionsDueData() {
  const [displayStatsForID, setDisplayStatsForID] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForID, setAllProjectsForID] = useState([]);
  const [isLoadingForID, setIsLoadingForID] = useState(true);

  const fetchStatsFuncForID = () => {
    customDashboard
    .fetch(`/divisions/${appDivisionId}/inspectionsDue/getInspectionsDueStats`)
    .then((res) => {
      setAllProjectsForID(res.data.inspectionsDue);
      setIsLoadingForID(false);
      setDisplayStatsForID(res.data.inspectionsDue);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForID()
  }, [appDivisionId]);

  return [
    displayStatsForID,
    allProjectsForID,
    isLoadingForID,
    setDisplayStatsForID,
    setIsLoadingForID,
    fetchStatsFuncForID
  ];
}


// Findings Overview Widget

export function useFindingsOverviewData() {
  const [displayStatsForFO, setDisplayStatsForFO] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForFO, setAllProjectsForFO] = useState([]);
  const [isLoadingForFO, setIsLoadingForFO] = useState(true);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/findingsOverview/getStats`)
      .then((res) => {
        setAllProjectsForFO(res.data.statistics);
        setIsLoadingForFO(false);
        setDisplayStatsForFO(res.data.statistics);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [
    displayStatsForFO,
    allProjectsForFO,
    isLoadingForFO,
    setDisplayStatsForFO,
    setIsLoadingForFO,
  ];
}

export function useFetchAllFindingTypesForFO() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForFO, setFindingTypeListForFO] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/findingsOverview/fetchFindingTypesUsed`)
      .then((res) => {
        setFindingTypeListForFO(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [findingTypeListForFO];
}


// Calendar Widget

export function useFetchProjectsListForCal() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForCal, setProjectsListForCal] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/calendar/fetchAllUserProjects`)
      .then((res) => {        
        setProjectsListForCal(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [projectsListForCal];
}