import instance from "../xhrService";

/**
 * Control measures
 */
const url = (id = null) => {
  return "/api/legend-items" + (id ? `/${id}` : "");
};

/** Generic Legend Item api  */
export const legendItemApi = {
  index: (projectId = null, type = null, documentGroupId = null, search, divisionId) => {
    if (!divisionId) {
      throw new Error('Division id is required 1');
    }

    return instance
      .get(url(null), {
        params: { type, projectId, documentGroupId, divisionId, s: search}
      })  
      .then(_ => _.data);
  },
  indexByProjectUsage: projectId => {
    return instance
      .get(`/api/projects/${projectId}/legend-items`)
      .then(_ => _.data);
  },
  fixedDetails: (projectId, doctype) => {
    const params = {}

      if (doctype) {
        params.doctype = doctype;
      }

    return instance
    .get(`/api/projects/${projectId}/fixed-details`, { params })
    .then(_ => _.data);
  },
  create: (projectId,divisionId, data) => {
    return instance
      .post(url(data.id || undefined), {
        ...data,
        projectId,
        divisionId
      })
      .then(_ => _.data);
  },
  copy: (divisionId, regulationIds, legendIds) => {
    return instance
      .post('/api/legend-items/copy', {
        divisionId,
        regulationIds,
        legendIds,
      })
      .then((_) => _.data);
  },
  createGlobal: data => {
    return instance.post(url(), data).then(_ => _.data);
  },
  update: (projectId, data) => {
    const reqData = { ...data };

    if (projectId) {
      reqData.projectId = projectId;
    }

    return instance.put(url(data.id), reqData).then(_ => _.data);
  },
  updateGlobal: data => {
    return legendItemApi.update(null, data);
  },
  destroy: (id, projectId) => {
    return instance
      .delete(url(id), {
        params: {
          projectId: projectId,
        },
      })
      .then(() => true);
  },
};

/**
 * Scope the index method of the generic {legendItemApi} with legend item type
 *
 */
export const controlMeasureApi = {
  ...legendItemApi,
  index: (projectId,divisionId) => legendItemApi.index(projectId, "control_measure",null,'',divisionId),
};

export const pollutantApi = {
  ...legendItemApi,
  index: (projectId,divisionId) => legendItemApi.index(projectId, "pollutant",null,'',divisionId),
};

export default legendItemApi;
