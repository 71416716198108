import instance from "../xhrService";

export type CAFindingModel = {
  id: number;
  name: string;
  date_initiated: string;
  initial_inspection_id: number;
  inspections: any[];
  location: string;
};

export const correctiveActionsFindingsApi = {
  index(projectId: number, filter: string, page: number, perPageDataCount: number): Promise<CAFindingModel[]> {
    return instance
      .get(`/api/projects/${projectId}/findingsWithLimit`,{
        params:{
          filter: filter,
          page: page,
          perPageDataCount: perPageDataCount
        }
      })
      .then(res => res.data);
  },
  getFindingFilterOptions(projectId: number){
    return instance
      .get(`/api/projects/${projectId}/getFindingFilterOptions`)
      .then(res => res.data);
  },
  getFindingModelPageCount(projectId: number, filter: string, page: number){
    return instance
      .get(`/api/projects/${projectId}/getFindingModelPageCount`,{
        params:{
          filter: filter,
          page: page
        }
      })
      .then(res => res.data[0]);
  }
};
