import clientApi from './api/client';
import divisionApi from './api/division';
import groupApi from './api/group';
import { IndexArg } from './api/indexArg';
import inspectionTemplateApi from './api/inspectionTemplate';
import legendItemApi from './api/legendItem';
import manualApi from './api/manuals';
import notificationApi from './api/notifications';
import rolesApi from './api/roles';
import userApi from './api/user';
import projectInspectionApi from "./api/projectInspection";
import { addFindingCount } from "../hooks/inspection";


/**
 * Resource index functions. May or may not support pagination.
 *
 * Use 'division' prefix to scope by division.
 * Non-divison-scoped models only work for division administrators
 *
 * @see {components/Shared/ResourceIndex/ResourceIndex}
 */
const apiResources: {
  [resourceName: string]: (args: IndexArg) => Promise<any[]>;
} = {
  users: ({ page, search }) => userApi.index(page, search),
  notifications: () => notificationApi.index(),
  clients: () => clientApi.index(),
  divisions: ({search}) => divisionApi.index(search),
  groups: () => groupApi.index(false),
  regulations: () => groupApi.index(false),
  legendItems: ({ search,divisionId }) => legendItemApi.index(null, null, null, search, divisionId),
  roles: () => rolesApi.index(),
  inspectionTemplates: ({search, divisionId}) => inspectionTemplateApi.indexAll(search, divisionId),
  manuals: () => manualApi.index(),
  divisionClients: ({ page, search, divisionId }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.clients.index(divisionId, page, search);
  },
  divisionProjects: ({ page, search, divisionId }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.projects.index(divisionId, page, search);
  },
  projectInspections: async ({ page, projectId, pageSize }) => {    
    if (!projectId) {
      throw new Error('projectId is required!');
    }
    const data = await projectInspectionApi.index(projectId, page, pageSize);

    const dataObj = data.pageData.map(addFindingCount)
    
    data.pageData = dataObj

    return  data;
  },
  divisionProjectArchive: ({ page, search, divisionId }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.projects.archive.index(divisionId, page, search);
  },
  divisionRegulations: ({ page, search, divisionId }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.regulations.index(divisionId, page, search);
  },
  divisionUsers: ({ page, search, divisionId }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.users.index(divisionId, page, search);
  },
  divisionInspectionTemplates: ({ divisionId, page, search }) => {
    if (!divisionId) {
      throw new Error('divisionId is required!');
    }

    return divisionApi.inspectionTemplates.index(divisionId,page, search);
  },
};

export default apiResources;
