import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import { RolesContext } from '../../../contexts/RolesContext';
import DashboardContext from '../../../contexts/DashboardContext';
// utils
import api from '../../../utils/api';
import divisionApi from '../../../utils/api/division';
import projectApi from '../../../utils/api/project';
import { ActionButtons } from '../../Shared/ActionButtons';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Error from '../../Shared/ResourceIndex/Error';
import SuccessNotification from '../../Shared/SuccessNotification/SuccessNotification';
import ProjectForm from '../Forms/ProjectForm';
import clientApi from '../../../utils/api/client';
import AppContext from '../../../contexts/AppContext';
import PrintInspectionsModal from './PrintInspectionsModal';
import PrintSiteMapModal from './PrintSiteMapsModal';
import { ProjectPrintProvider } from '../../../contexts/ProjectPrintContext';
import PrintSwmpModal from './PrintSwmpModal';
import { extractInitialData } from '../Forms/projectFormData';
import XHR from '../../../utils/api/publicUrl';
import { env } from '../../../config';
import { Button, Modal } from "@sw-sw/lib-ui"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

function ProjectHeader({ onDelete, onUpdate }) {
  const [showDeleteModal, setDeleteModalVis] = useState(false);
  const [showEditModal, setEditModalVis] = useState(false);
  const [showSuccess, setSuccessVis] = useState(false);
  const [showPrintSwmpModal, setPrintSwmpModalVis] = useState(false);
  const [showPrintInspectionsModal, setShowPrintInspectionsModal] =
    useState(false);
  const [showPrintSiteMapModal, setShowPrintSiteMapModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showQRmodal, setShowQRmodal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false)
  const [disableImg, setDisableImg] = useState(false)
  const [error, setError] = useState('');
  const [client, setClient] = useState();
  const [value, setValue] = React.useState('withImage');

  const history = useHistory();

  const roleStore = useContext(RolesContext);
  const projectContext = useContext(ProjectContext);
  const { readOnly, setReadOnly } = useContext(ProjectPermissionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);
  const { project, projectDocs } = projectContext;
  const { getTeamManagementData, fetchStatsFuncForID } = useContext(DashboardContext);

  const userCanEdit = roleStore.userHasPermission('update', 'Project');
  const userCanDelete = roleStore.userHasPermission('delete', 'Project');
  const userCanArchive = roleStore.userHasPermission('update', 'Project');
  const userCanCreatePublic = roleStore.userHasPermission('all', 'QR Access');
  const secondaryActions = useRef([]);
  // const userCanRemoveDuplicateLayer = roleStore.userHasPermission('all', 'Duplicate Map Layer');

  const handlePublic = () => {
    const projectId = projectContext.project.id

    localStorage.setItem("Qr Access", roleStore.userHasPermission('all', 'QR Access'))

    if (value === "withImage") {
      XHR.getPublicLink(projectId).then((res) => {
        window.open(`${window.location.origin}/public/divisions/${appDivisionContext.appDivisionId}/projects/${projectId}/inspections`);
      });
      localStorage.removeItem("No Image")
    } else {
      XHR.getPublicLink(projectId).then((res) => {
        window.open(`${window.location.origin}/public/divisions/${appDivisionContext.appDivisionId}/projects/${projectId}/inspections?image=false`);
        localStorage.setItem("No Image", true)
      });
    }
    setShowViewModal(false)
  }

  const removeDuplicateLayer = async () => {
    const response = await projectApi.removeDuplicateLayers(projectContext.project.id)

    alert(response)
  }


  /** set up secondary actions */
  useEffect(() => {
    const regex = /..*\/archive\/.*/;

    let isArchive = regex.test(window.location.pathname);

    if (userCanEdit && !readOnly) {
      secondaryActions.current.push({
        icon: 'pencil',
        label: 'Edit',
        onClick: async () => {
          await projectContext.loadDocs(project.id);
          setEditModalVis(true);
        },
      });
    }

    if (roleStore.userHasPermission('all', 'Download')) {
      secondaryActions.current.push(
        {
          icon: 'print',
          label: 'Print Project Documents',
          onClick: () => setPrintSwmpModalVis(true),
        },
        {
          icon: 'print',
          label: 'Print Inspections',
          onClick: () => setShowPrintInspectionsModal(true),
        },
        {
          icon: 'print',
          label: 'Print Site Maps',
          onClick: () => setShowPrintSiteMapModal(true),
        },
      );
    }

    if (userCanDelete) {
      secondaryActions.current.push({
        icon: 'trash',
        label: 'Delete',
        onClick: () => setDeleteModalVis(true),
      });
    }

    if (userCanCreatePublic) {
      secondaryActions.current.push({
        label: 'Public View',
        icon: 'folder',
        onClick: () => setShowViewModal(true),
      });
    }

    if (userCanArchive && !isArchive) {
      secondaryActions.current.push({
        icon: 'archive',
        label: 'Archive',
        onClick: () => setShowArchiveModal(true),
      });
    }

    if (userCanArchive && isArchive) {
      secondaryActions.current.push({
        icon: 'undo',
        label: 'Restore',
        onClick: () => setShowRestoreModal(true),
      });
    }

    // if (userCanRemoveDuplicateLayer) { //Actual condition
    if (appContext.get("user").roleName === 'Admin') { //Tempororary condition until Matt's testing
      secondaryActions.current.push({
        icon: 'trash',
        label: 'Remove Duplicate Layers',
        onClick: () => removeDuplicateLayer(),
      });
    }

    return () => (secondaryActions.current.length = 0);
  }, []);

  useEffect(() => {
    appContext.loadData(
      () => clientApi.index([project.client_id]).then((c) => {
        projectContext.setDocumentDate(c[0].document_date)
        setClient(c)
        setDisableImg(c[0].disable_images)
      }),
      'project details',
    );
  }, [project]);

  if (!project) {
    return <Error error='project is undefined' />;
  }

  function handleDelete() {
    return api
      .delete(`/api/projects/${project.id}`)
      .then((res) => {
        setDeleteModalVis(false);
        onDelete();
      })
      .catch((e) => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'An error has occurred',
        );
      });
  }

  function handleArchive() {
    return divisionApi.projects.archive
      .archive(appDivisionContext.appDivisionId, project.id)
      .then((data) => {
        setShowArchiveModal(false);
        history.push(appDivisionContext.getPath('/projects'));
      })
      .catch((e) => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'An error has occurred',
        );
      });
  }

  function handleRestore() {
    return divisionApi.projects.archive
      .restore(appDivisionContext.appDivisionId, project.id)
      .then((data) => {
        projectContext.setProject({
          ...projectContext.project,
          ...data,
        });
        history.push(
          `/divisions/${appDivisionContext.appDivisionId}/projects/${project.id}`,
        );
        setShowRestoreModal(false);
        setReadOnly(false);
      })
      .catch((e) => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'An error has occurred',
        );
      });
  }

  function handleEdit(formData) {
    return projectApi.update(project.id, formData).then((data) => {
      setSuccessVis(true);
      onUpdate(data);
      getTeamManagementData();
      fetchStatsFuncForID();
      window.setTimeout(() => {
        setSuccessVis(false);
        setEditModalVis(false);
      }, 2000);
    });
  }

  const countryOrState = () => {
    if (env.REACT_APP_REGION === "UK") {
      return `${project.address.countryObj ? project.address.countryObj.name : ""} `
    }

    return `${project.address.state ? project.address.state.abbreviation : ""} `
  }

  return (
    <Fragment>
      <section className='project-header pure-g'>
        <div className='project-info pure-u pure-u-1'>
          <div className='project-info-row'>
            <div className='project-info-column overflow-hidden'>
              <div className='project-title-seprator'>
                <h2>
                  <FontAwesomeIcon icon='folder' className='header-icon' />
                  {project.name}
                </h2>
                <div className='project-buttons pure-u pure-u-md-1-3 pure-u-sm-1-2 pure-u-1'>
                  <ActionButtons secondary={secondaryActions.current} />
                </div>
              </div>
              <h5>
                <FontAwesomeIcon icon='user' className='header-icon' />
                {client ? client[0].name : ''}
              </h5>
            </div>
          </div>

          {/* don't bother showing address if neither are set */}
          {project.address.street_1 ||
            project.address.city ||
            (project.address.state || project.address.countryObj) ||
            project.address.zip ? (
            <h5>
              <FontAwesomeIcon icon='map-pin' className='header-icon' />
              {project.address.street_1}
              {project.address.street_1 ? ', ' : ''}
              {project.address.city}
              {project.address.city ? ', ' : ''}
              {countryOrState()}
              {project.address.zip}
            </h5>
          ) : null}
        </div>
      </section>

      {/* modals */}
      <ConfirmationModal
        show={showDeleteModal}
        handleConfirm={handleDelete}
        handleClose={() => setDeleteModalVis(false)}
        title='Are you sure you want to delete this project?'
        subTitle={`All documents and contacts associated with ${project.name} will be deleted.`}
        buttonText='Yes, I understand'
        errorMsg={error}
      />

      <ConfirmationModal
        show={showArchiveModal}
        handleConfirm={handleArchive}
        handleClose={() => setShowArchiveModal(false)}
        title='Are you sure you want to archive this project?'
        subTitle={`This will archive the ${project.name} project, making it unavailable in searches and lists.`}
        buttonText='Yes, I understand'
        errorMsg={error}
      />

      {/* <ConfirmationModal
        show={showViewModal}
        handleConfirm={handlePublic}
        handleClose={() => setShowViewModal(false)}
        title='Public View '
        subTitle={`This will archive the ${project.name} project, making it unavailable in searches and lists.`}
        buttonText='Yes, I understand'
        hideCancel={true}
      /> */}

      <ConfirmationModal
        show={showRestoreModal}
        handleConfirm={handleRestore}
        handleClose={() => setShowRestoreModal(false)}
        title='Are you sure you want to restore this project?'
        subTitle={`This will restore the ${project.name} project, making it available again in searches and lists.`}
        buttonText='Yes, I understand'
        errorMsg={error}
      />

      <ConfirmationModal
        show={showQRmodal}
        handleConfirm={handleRestore}
        handleClose={() => setShowQRmodal(false)}
        title='Are you sure you want to restore this project?'
        subTitle={`This will restore the ${project.name} project, making it available again in searches and lists.`}
        buttonText='Yes, I understand'
        errorMsg={error}
      />

      <Modal
        show={showViewModal}
        title="View Public Page"
        handleClose={() => setShowViewModal(false)}
        hideButtons={true}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={(ele) => setValue((ele.target).value)}
          >
            {disableImg ? null : <FormControlLabel value="withImage" control={<Radio />} label="Public View" />}
            <FormControlLabel value="withOutImage" control={<Radio />} label="Public View Without Image" />
          </RadioGroup>

          <Button
            onClick={handlePublic}
          >
            Generate Public View
          </Button>
        </FormControl>
      </Modal>

      <SuccessNotification show={showSuccess} />
      {
        showEditModal && (
          <ProjectForm
            handleSubmit={handleEdit}
            handleClose={() => setEditModalVis(false)}
            submitBtnText='Save'
            initialData={extractInitialData(
              { ...project, inspections: projectContext.inspections || [], client },
              projectDocs.documentTypes,
            )}
            hideStartDate={projectContext.inspections.length > 0}
          />
        )
      }

      {
        showPrintSwmpModal && (
          <ProjectPrintProvider>
            <PrintSwmpModal onClose={() => setPrintSwmpModalVis(false)} />
          </ProjectPrintProvider>
        )
      }
      {
        showPrintInspectionsModal && (
          <ProjectPrintProvider>
            <PrintInspectionsModal
              onClose={() => setShowPrintInspectionsModal(false)}
            />
          </ProjectPrintProvider>
        )
      }
      {
        showPrintSiteMapModal && (
          <ProjectPrintProvider>
            <PrintSiteMapModal onClose={() => setShowPrintSiteMapModal(false)} />
          </ProjectPrintProvider>
        )
      }
    </Fragment >
  );
}

ProjectHeader.propTypes = {
  onUpdate: func.isRequired,
  onDelete: func.isRequired,
};

export default ProjectHeader;
