import React, { useContext } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import ResourceIndex from '../Shared/ResourceIndex/ResourceIndex';
import RolesContext from '../../contexts/RolesContext';
import InspectionTemplateAdd from './InspectionTemplateAdd';
import { Modal } from '@sw-sw/lib-ui';
import AppContext from '../../contexts/AppContext';
import InspectionTemplateCopy from '../LegendItems/Form/InspectionTemplateCopy';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import { get } from 'lodash';

const InspectionTemplates: React.FC = () => {
  const rolesContext = useContext(RolesContext);
  const appStore = useContext(AppContext);

  const user = appStore.get('user');

  const appDivisionContext = useContext(AppDivisionContext);
  const routeMatch = useRouteMatch();

  const divisionId = get(
    routeMatch.params,
    'division_id',
    appDivisionContext.appDivisionId,
  );


  return (
    <React.Fragment>
      <ResourceIndex
        resourceName='inspectionTemplates'
        resourceTableHeaders={[['Name', 'name']]}
        resourceSearchProps={['name']}
        resourceQueryProps={{ divisionId: divisionId }}
        enableRead={rolesContext.userHasPermission(
          'read',
          'Inspection Templates',
        )}
        enableClick={rolesContext.userHasPermission(
          'update',
          'Inspection Templates',
        )}
        enablePagination={false}
        enableCallToAction={rolesContext.userHasPermission(
          'create',
          'Inspection Templates',
        )}
        enableSelectAll={user.roleName === 'Admin'}
        callToActionContent={'Inspection Template'}
        renderReader={(inspectionTemplate, handleClose) => {
          if (!inspectionTemplate.isUserTemplate) {
            return (
              <Modal
                show={true}
                handleSubmit={handleClose}
                handleClose={handleClose}
                title='Error'
                subTitle='System Templates can not be edited at this time.'
              />
            );
          } else {
            return (
              <Redirect
                push
                to={`/divisions/${divisionId}/inspection-templates/${inspectionTemplate.id}`}
              />
            );
          }
        }}
        renderCallToAction={(
          handleClose,
          pushData,
          refetch,
          setSelectedItems,
          selectedItems,
        ) => {
          console.log('refetch>', refetch);

          if (selectedItems.length > 0) {
            return (
              <InspectionTemplateCopy
                onSubmit={(data: any) => {
                  pushData(data);
                  setSelectedItems([]);
                  handleClose();
                }}
                onCancel={handleClose}
                selectedItems={selectedItems}
                role={user.roleName}
              />
            );
          } else {
            return <InspectionTemplateAdd handleClose={handleClose} />;
          }
        }}
        hideCallToActionInMobile={false}
      />
    </React.Fragment>
  );
};

export default InspectionTemplates;
