import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
// import update from "immutability-helper";

// components and helpers
import GroupDocuments from "./GroupDocuments";
import ResourceDetailTitle from "../Shared/ResourceDetail/ResourceDetailTitle";
import ResourceDetailTabs from "../Shared/ResourceDetail/ResourceDetailTabs";

import RegulationContext from "../../contexts/RegulationContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import RolesContext from "../../contexts/RolesContext";
import { LoadingMessage } from "@sw-sw/lib-ui";
import {
  useDeleteRegulationDoc,
  useGroupDocs,
  useBmpDocs,
  useUpdateRegulationDoc,
  useUploadRegulationDoc,
  useUploadBmpDoc,
  useOnMoveRegulationDoc,
  useOnDropRegulationDoc,
  useDeleteBmpDoc,
  ScopeType,
  useUpdateBmpDoc,
  useOnMoveBmpDoc,
  useOnDropBmpDoc,
} from "../../hooks/groupDocs";

import useResource from "../../hooks/resource";
import ResourceSearch from "../Shared/Resource/Search";
import { ActionButtons } from "../Shared/ActionButtons";
import GroupDetailsForm from '../Groups/Forms/GroupDetailsForm'
import groupApi from "../../utils/api/group";
import ResourceDetailDelete from "../Shared/ResourceDetail/ResourceDetailDelete";

const GroupDetailUI = props => {
  const regulationContext = useContext(RegulationContext);
  const { getPath,appDivisionId } = useContext(AppDivisionContext);
  const { id } = props.match.params;
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const groupDocsQuery = useGroupDocs(id);
  const bmpDocsQuery = useBmpDocs(id,appDivisionId);
  const deleteRegulationDocMutation = useDeleteRegulationDoc(id);
  const deleteBmpDocMutation = useDeleteBmpDoc(id);
  const uploadRegulationMutation = useUploadRegulationDoc(id);
  const uploadBmpMutation = useUploadBmpDoc(id);
  const updateRegulationMutation = useUpdateRegulationDoc(id);
  const updateBmpMutation = useUpdateBmpDoc(id);
  const moveRegulationMutation = useOnMoveRegulationDoc(id);
  const moveBmpMutation = useOnMoveBmpDoc(id);
  const dropRegulationMutation = useOnDropRegulationDoc();
  const dropBmpMutation = useOnDropBmpDoc();
  const { search } = useResource({ resource: 'divisionProjects' });
  const { query, setQuery } = search;
  const { permCheck } = props;
  const [disableDelete, setDisableDelete] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
 
  const formatScope = scope => {
    return `${scope}_docs`;
  };

  const onDocumentDelete = (docId, scope, groupId) => {

    formatScope(scope) === ScopeType.REGULATIONS_DOCS || groupId === 0
      ? deleteRegulationDocMutation.mutate({ scope: formatScope(scope), docId, groupId })
      : deleteBmpDocMutation.mutate({ scope: formatScope(scope), docId});
  };

  const updateGroup = group => {
    return regulationContext.updateRegulation(group);
  };

  const deleteGroup = () => {
    props.history.push(getPath("/groups"));
  };

  const onDocumentUpload = (file, scope, legendItemId) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
    || (formatScope(scope) === ScopeType.BMP_DOCS && !legendItemId)
      ? uploadRegulationMutation.mutate({
          scope: formatScope(scope),
          file,
          fixed: true
        })
      : uploadBmpMutation.mutate({
          scope: formatScope(scope),
          file,
          legendItemId,
        });
  };

  const onDocumentEdit = (file, scope, fixed) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS || !fixed
      ? updateRegulationMutation.mutate({ scope: formatScope(scope), file, fixed:true })
      : updateBmpMutation.mutate({ scope: formatScope(scope), file });
  };

  const onMove = ({ scope, hoverIndex, dragIndex, legendItemId }) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? moveRegulationMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          legendItemId,
        })
      : moveBmpMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          legendItemId,
        });
  };

  const onDrop = ({ scope, hoverIndex, dragIndex, document, index }) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? dropRegulationMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          document,
          index,
        })
      : dropBmpMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          document,
          index,
        });
  };

  const docs = useMemo(() => {
    const organizeDocs = () => {
      const regulations_docs = [
        { name: "Regulations", id: 0, group_docs_jt: [] },
      ].map(regulation => {
        return { ...regulation, group_docs_jt: groupDocsQuery.data.group_docs_jt.filter((doc) => {

          if(doc.group_uploads){
            
            return doc.group_uploads.doctypes[0].name === "Regulations"
          }

          return doc.doc_type ? doc.doc_type.name === "Regulations" : doc.docType === "Regulations"
        })};
      });

      const newdoc = groupDocsQuery.data.group_docs_jt.filter((doc) => {
        if(doc.group_uploads){
          return doc.group_uploads.doctypes[0].name === "Fixed Details"
        }

        return doc.doc_type ? doc.doc_type.name === "Fixed Details" : doc.docType === "Fixed Details"
      })

      return {
        regulations_docs,
        bmp_docs: bmpDocsQuery.data,
        groupData: groupDocsQuery.data,
        fixedDetails:newdoc.length ? newdoc : [{id: -1, mime_type: 'application/pdf'}]
      };
    };

    if (groupDocsQuery.data || bmpDocsQuery.data) {
      return organizeDocs();
    } else {
      return {};
    }
  }, [groupDocsQuery, bmpDocsQuery]);

  useEffect(() => {
    if (docs && docs.groupData) {
      updateGroup(docs.groupData);
    }
   
  }, [docs]);
  

  if (!groupDocsQuery.data || !bmpDocsQuery.data) {
    return <LoadingMessage what="Documents" />;
  }

  const searchProps = {
    query,
    setQuery(input) {
      setQuery(input);
    },
    placeholder: 'Search Regulations or Legend Details..',
  };

  function handleSubmit(formState) {
    
    if(!formState.name.trim()){
      throw new Error("Regulation name cannot be empty")
    }

    return groupApi.update(regulationContext.regulation.id, formState).then(group => {
      updateGroup(regulationContext.regulation);
      setShowEdit(false);

      return Promise.resolve();
    });
  }

  const secondaryActions = [
    {
    label: 'Edit Group',
    icon: 'edit',
    onClick: () => setShowEdit(true),
    },
    {
      label: 'Delete Group',
      icon: 'trash',
      onClick: () => setShowDelete(true),
    },
  ]

  const handleDelete = () => {
    groupApi
      .destroy(regulationContext.regulation.id)
      .then(() => {
        setShowDelete(false);
        setDisableDelete(true);
        deleteGroup();
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.message) {
          setErrorMsg(e.response.data.message);
        } else {
          setErrorMsg("An error has occurred");
        }
      });
  };

  const showModal = () => {
    setShowDelete(true);
    setErrorMsg("");
  };

  const hideModal = () => {
    setShowDelete(false);
    setDisableDelete(true);
  };
  
  return regulationContext.regulation ? (
    <section className="project-detail group-detail">
      <div className="group-header">
        {regulationContext.regulation && regulationContext.regulation.name ? (
          <ResourceDetailTitle title={regulationContext.regulation.name} />
        ) : null}
        <div>
        {
         showEdit && <GroupDetailsForm 
          handleSubmit={handleSubmit}
          handleClose={() => setShowEdit(false)}
          title="Edit Group Details"
          group={regulationContext.regulation}
          />
        }
        {
           showDelete && <ResourceDetailDelete
           resource="Group"
           handleSubmit={handleDelete}
           confirmationMsg={
             <>
               All documents and contacts associated with{" "}
               <strong>{regulationContext.regulation.name}</strong> will be deleted. Do you wish to proceed?
             </>
           }
           show={showDelete}
           errorMsg={errorMsg}
           hideModal={hideModal}
           showModal={showModal}
           disableDelete={disableDelete}
           toggleDisabled={() => setDisableDelete(!disableDelete)}
           icon={false}
         />
        }
        </div>
      </div>
      <ActionButtons secondary={secondaryActions} />
      <ResourceSearch local={false} {...searchProps} />

      <ResourceDetailTabs
        headers={["Regulations", "Legend Details"]}
        panels={[
          regulationContext.regulation &&
          regulationContext.regulation.group_docs_jt
            ? () => (
                <GroupDocuments
                  scope="regulations"
                  // @TODO fetch docs from db
                  documentGroups={docs.regulations_docs}
                  document_group_id={regulationContext.regulation.id}
                  onDocumentUpload={onDocumentUpload}
                  onDocumentEdit={onDocumentEdit}
                  onDocumentDelete={onDocumentDelete}
                  onMove={e => onMove(e, "regulations")}
                  onDrop={e => onDrop(e, "regulations")}
                  canDrag={permCheck("update", "Regulations")}
                  label={"Regulations"}
                  roleCanDelete={permCheck("update", "Regulations")}
                  searchValue={search}
                />
              )
            : () => "No Documents",
          () => (
            <GroupDocuments
              scope="bmp"
              documentGroups={docs.bmp_docs}
              document_group_id={regulationContext.regulation.id}
              onDocumentUpload={onDocumentUpload}
              onDocumentEdit={onDocumentEdit}
              onDocumentDelete={onDocumentDelete}
              onMove={e => onMove(e, "bmp")}
              onDrop={e => onDrop(e, "regulations")}
              canDrag={permCheck("update", "Regulations")}
              label={"Regulations"}
              roleCanDelete={permCheck("update", "Regulations")}
              searchValue={search}
              fixedDetails={docs.fixedDetails}
            />
          ),
        ].filter(_ => _)}
      />
    </section>
  ) : (
    <LoadingMessage what="Group Documents" />
  );
};

GroupDetailUI.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  permCheck: PropTypes.func.isRequired,
};

const GroupDetail = props => {
  const permCheck = useContext(RolesContext).userHasPermission;

  return <GroupDetailUI {...props} permCheck={permCheck} />;
};

export default GroupDetail;
