import React, { useState, useContext, useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import DashboardContext from "../../../../contexts/DashboardContext";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import { faCheckCircle as faCheckCircleRegular } from '@fortawesome/free-regular-svg-icons';
import {
    faCheckCircle as faCheckCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import BulkAcknowledgementCertifyModal from "../../../Inspections/Certification/BulkAcknowledgementCertifyModal";
import SuccessNotification from "../../../Shared/SuccessNotification/SuccessNotification";
import _ from 'lodash';
import moment from "moment";
import { dateFormat } from "../../../../utils";



const AcknowledgementCert = ({
  showAcknowledgementCertifyModal,
  setShowAcknowledgementCertifyModal,
  acknowledgementSelectedCerts,
  setAcknowledgementSelectedCerts,
}:{
  showAcknowledgementCertifyModal: any,
  setShowAcknowledgementCertifyModal: any,
  acknowledgementSelectedCerts: any,
  setAcknowledgementSelectedCerts: any,
}) => {
  const { isLoadingForAcknowledgementCert, projectsListForAcknowledgementCert, setProjectsListForAcknowledgementCert } = useContext<any>(DashboardContext)
  const [showSuccess, setSuccessVis] = useState<boolean>(false);
  const [filterColumns, setFilterColumns] = useState<any>({});
  const ref = useRef<any>()

  // icons holder
  const [siteNameIcon, setSiteNameIcon] = useState<any>(faSort);
  const [clientNameIcon, setClientNameIcon] = useState<any>(faSort);
  const [dateOfInspectionIcon, setDateOfInspectionIcon] = useState<any>(faSort);
  
  const updateSiteNameIcon = (order: string) => {
    if (order == "desc") {
        setSiteNameIcon(faSortDown);
    } else if (order == "asc") {
        setSiteNameIcon(faSortUp);
    } else {
        setSiteNameIcon(faSort);
    }
    setClientNameIcon(faSort);
    setDateOfInspectionIcon(faSort);
  }

  const updateClientNameIcon = (order: string) => {
    setSiteNameIcon(faSort);
    if (order == "desc") {
        setClientNameIcon(faSortDown);
    } else if (order == "asc") {
        setClientNameIcon(faSortUp);
    } else {
        setClientNameIcon(faSort);
    }
    setDateOfInspectionIcon(faSort);
  }

  const updateDateOfInspectionIcon = (order: string) => {
    setSiteNameIcon(faSort)
    setClientNameIcon(faSort);
    if (order == "desc") {
        setDateOfInspectionIcon(faSortDown);
    } else if (order == "asc") {
        setDateOfInspectionIcon(faSortUp);
    } else {
        setDateOfInspectionIcon(faSort);
    }
  }

  const handleSaveAnimation = useCallback(() => {
    window.setTimeout(() => setSuccessVis(false), 2000);

    return setSuccessVis(true);
  }, [showSuccess]);


  const handleSelectAll = () => {
    if (projectsListForAcknowledgementCert.length !== acknowledgementSelectedCerts.length) {
        const temp_selected_certs = projectsListForAcknowledgementCert.map((ele: any) => {
            return ele.id
        });

        setAcknowledgementSelectedCerts(temp_selected_certs);
    } else {
        setAcknowledgementSelectedCerts([]);
    }
  }

  const changeCheckboxSelection = (id: number, currentState: boolean): void => {
    let tempSelectionArr: any = [...acknowledgementSelectedCerts];

    if (currentState == false && tempSelectionArr.includes(id)) {
        tempSelectionArr = tempSelectionArr.filter((e: number) => e !== id);
    } else {
        tempSelectionArr.push(id);
    }

    setAcknowledgementSelectedCerts(tempSelectionArr);
  }

  const handleSort = (columnName: string) => {
    let tempFilterColumns: any = { ...filterColumns };

    if (Object.keys(tempFilterColumns)[0] !== columnName) {
        tempFilterColumns = {};
    }

    if (!tempFilterColumns[columnName]) {
        tempFilterColumns[columnName] = "desc";
    } else if (tempFilterColumns[columnName] && tempFilterColumns[columnName] === "desc") {
        tempFilterColumns[columnName] = "asc";
    } else {
        if (tempFilterColumns[columnName]) { delete tempFilterColumns[columnName] };
    }

    setFilterColumns(tempFilterColumns);
  }

  useEffect(() => {
    const temp = [...projectsListForAcknowledgementCert];

    let newSortOrderList = [];

    if (Object.keys(filterColumns).includes("siteName")) {
        newSortOrderList = _.orderBy(temp, [(o) => o.project.name.toLowerCase()], [filterColumns.siteName]);
        updateSiteNameIcon(filterColumns.siteName);
    } else if (Object.keys(filterColumns).includes("clientName")) {
        newSortOrderList = _.orderBy(temp, [(o) => o.project.client.name.toLowerCase()], [filterColumns.clientName]);
        updateClientNameIcon(filterColumns.clientName);
    } else if (Object.keys(filterColumns).includes("dateOfInspection")) {
        newSortOrderList = _.orderBy(temp, [(o) => o.created_date], [filterColumns.dateOfInspection]);
        updateDateOfInspectionIcon(filterColumns.dateOfInspection);
    } else {
        newSortOrderList = _.orderBy(temp, [(o) => o.id], ["desc"]);
        updateSiteNameIcon("");
    }
    setProjectsListForAcknowledgementCert(newSortOrderList)
  }, [filterColumns]);
  
  return (
    <Table stickyHeader aria-label="sticky table" size='small'>
      <TableHead className='widget-head'>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              icon={<FontAwesomeIcon icon={faCheckCircleRegular} size={'lg'} className={'checkboxDeactive'} />}
              checkedIcon={<FontAwesomeIcon icon={faCheckCircleSolid} size={'lg'} className={'checkboxActive'} />}
              checked={projectsListForAcknowledgementCert.length === acknowledgementSelectedCerts.length && acknowledgementSelectedCerts.length !== 0}
              onChange={() => { return handleSelectAll() }}
              inputProps={{ 'aria-label': 'controlled' }}
              value={projectsListForAcknowledgementCert.length === acknowledgementSelectedCerts.length}
            />
          </TableCell>
          <TableCell className='table-fix-th-1'>
            <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("siteName") }} >
              Site Name <FontAwesomeIcon icon={siteNameIcon} size={'sm'} className={''} />
            </Typography>
          </TableCell>
          <TableCell className='table-fix-th-2'>
            <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("clientName") }} >
              Client Name <FontAwesomeIcon icon={clientNameIcon} size={'sm'} className={''} />
            </Typography>
          </TableCell>
          <TableCell className='table-fix-th'>
            <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("dateOfInspection") }} >
              Date Of Inspection <FontAwesomeIcon icon={dateOfInspectionIcon} size={'sm'} className={''} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody className='widget-body' ref={ref}>
        {!isLoadingForAcknowledgementCert ?
          (projectsListForAcknowledgementCert && projectsListForAcknowledgementCert.map((ele:any) => {

            const isChecked = acknowledgementSelectedCerts.includes(ele.id);

            return (
              <TableRow
                hover
                selected={isChecked}
                key={ele.id}
                className='widget-body-data'
              >
                <TableCell padding="checkbox" sx={{ borderBottom: "none" }}>
                <Checkbox
                    icon={<FontAwesomeIcon icon={faCheckCircleRegular} size={'lg'} className={'checkboxDeactive'} />}
                    checkedIcon={<FontAwesomeIcon icon={faCheckCircleSolid} size={'lg'} className={'checkboxActive'} />}
                    checked={isChecked}
                    onChange={() => { return changeCheckboxSelection(ele.id, !isChecked) }}
                    value={isChecked}
                />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} className='table-fix-td-1'>
                    <Typography component={'span'} variant="body1" className='cell-elm'>{ele.project.name}</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} className='table-fix-td-2'>
                    <Typography component={'span'} variant="body1" className='cell-elm'>{ele.project.client.name}</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} className='table-fix-td'>
                    <Typography component={'span'} variant="body1" className='cell-elm'>{moment(ele.created_date).format(dateFormat)}</Typography>
                </TableCell>
              </TableRow>
            )
          })) :
          (<Loading what="data" />)
        }
      </TableBody>

      {showAcknowledgementCertifyModal && (
        <BulkAcknowledgementCertifyModal
          hideModal={() => setShowAcknowledgementCertifyModal(false)}
          inspections={projectsListForAcknowledgementCert.filter(
            (inspection: { id: any }) =>
              acknowledgementSelectedCerts.includes(inspection.id),
          )}
          handleSaveAnimation={handleSaveAnimation}
          // onSubmit={() => dashboardStore.inspectionsCertDue.refetch()}
        />
      )}

      <SuccessNotification show={showSuccess} />
    </Table>
  )
}


export default AcknowledgementCert