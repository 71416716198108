import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import {
  // faEllipsisV,
  faExternalLinkAlt,
  faChevronCircleDown,
  faChevronCircleRight,
  faCheckSquare as faCheckCircleRegular,
  faCheckSquare as faCheckCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Loading from '../../../Shared/ResourceIndex/Loading';
import DashboardContext from '../../../../contexts/DashboardContext';
import {
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Checkbox,
  Button,
  TableBody,
  Table,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import ReassignModal from '../../../Dashboard/ReassignModal';
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover';
import SuccessNotification from '../../../Shared/SuccessNotification/SuccessNotification';
import moment from "moment";
import classNames from "classnames";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#385980',
    fontWeight: 900,
    borderBottom: 'none',
    padding: '0 0 0 5px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    color: '#6D747E',
    padding: '0 0 2px 5px',
  },
}));
const TeamManagement = ({ providedProps, changeShowMore, mdSize }) => {
  const searchInput = useRef('');

  const [showModal, setShowModal] = useState(false);

  const [dropDownValues, setDropDownValues] = useState([]);

  const [formInitialData, setFormInitialData] = useState({});

  const [selectedDropdown, setSelectedDropdown] = useState(' ');

  const [showSuccess, setSuccessVis] = useState(false);

  const { getTeamManagementData, teamManagementData, isLoadingForTM } =
    useContext(DashboardContext);
  const widgetRef = useRef(0);

  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const loadInitialData = () => {
    if (!teamManagementData || !teamManagementData.team) return;

    const newData = teamManagementData.team.map((data) => {
      const filteredItem = data.inspections.filter((insp) => insp.dueDate === "Invalid date")
      
      filteredItem.forEach((e) => {
        if(data.inspections.includes(e)){
          const newItem = data.inspections.splice(data.inspections.indexOf(e), 1)[0]

          data.inspections.push(newItem)

          return data
        }
      })

      data.inspections.sort(
        (a, b) => new Date(a.dueDate.replace(/-/g,'/')) - new Date(b.dueDate.replace(/-/g,'/')),
      );

      return { ...data, isOpen: false };
    });

    setDropDownValues(teamManagementData.dropDown);
    setFilteredData(newData);
  };

  useEffect(() => {
    loadInitialData();
  }, [teamManagementData]);

  const handleClick = (selectedId) => {
    const collapsedData = filteredData.map((ele) => {
      if (ele.id === selectedId) {
        ele.isOpen = !ele.isOpen;
      }

      return ele;
    });

    setFilteredData(collapsedData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = (value) => {
    let newFilteredData = [...filteredData];
    const FilteredData = newFilteredData.map((ele) => {
      if (ele.id === value) {
        ele.checked = !ele.checked;
        ele.inspections = ele.inspections.map((item) => {
          item.checked = ele.checked;

          return item;
        });
      }

      return ele;
    });

    setFilteredData(FilteredData);
  };

  const handleToggle = (value) => {
    const FilteredData = filteredData.map((ele) => {
      ele.inspections.forEach((item) => {
        if (item.projectId === value) {
          item.checked = !item.checked;
        }
      });

      return ele;
    });

    setFilteredData(FilteredData);
  };

  const handleDropDownChange = (e) => {
    setSelectedDropdown(e.target.value);

    if (e.target.value !== ' ') {
      const FilteredData = teamManagementData.team.filter(
        (ele) => {
          if(e.target.value === 'Manager'){
            return (ele.roleName ==='Manager' || ele.roleName === 'Client Inspector' || ele.roleName === 'Client Manager')
          }

          return ele.roleName === e.target.value
        },
      );

      setFilteredData(FilteredData);
    } else {
      setFilteredData(teamManagementData.team);
    }
    searchInput.current.value = '';
  };

  const searchInspections = (value, ele) => {
    const filteredInspections = ele.inspections.filter((item) =>
      item.siteName.toLowerCase().includes(value.toLowerCase()),
    );

    if (filteredInspections.length > 0) {
      return filteredInspections;
    } else {
      return ele.inspections;
    }
  };

  const handleSearchChange = _.debounce((e) => {
    const { value } = e.target;

    if (value !== '') {
      let newFilteredData = _.cloneDeep(teamManagementData.team);

      const FilteredData = newFilteredData
        .filter(
          (ele) =>
            (ele.roleName === selectedDropdown || selectedDropdown === ' ') &&
            (ele.name.toLowerCase().includes(value.toLowerCase()) ||
              ele.inspections.filter((item) =>
                item.siteName.toLowerCase().includes(value.toLowerCase()),
              ).length > 0),
        )
        .map((ele) => {
          return {
            ...ele,
            inspections: searchInspections(value, ele),
            isOpen: true,
          };
        });

      setFilteredData(FilteredData);
    } else {
      setFilteredData(
        teamManagementData.team.filter(
          (ele) =>
            ele.roleName === selectedDropdown || selectedDropdown === ' ',
        ),
      );
    }
  }, 500);

  const handleReassignClick = () => {
    const currInspectorId = filteredData[0].id;

    const selectedInspections = filteredData.reduce((acc, ele) => {
      const selected = ele.inspections.filter((item) => item.checked);

      return [...acc, ...selected];
    }, []);

    setFormInitialData({
      inspectorId: currInspectorId,
      projectIds: _.uniq(
        selectedInspections.map((project) => project.projectId),
      ),
      clientIds: _.uniq(selectedInspections.map((project) => project.clientId)),
    });
    selectedInspections.length > 0 && setShowModal(true);
  };

  const handleSaveAnimation = useCallback(() => {
    window.setTimeout(() => setSuccessVis(false), 2000);

    return setSuccessVis(true);
  }, [showSuccess]);

  const handleReassign = (resp, activeView) => {
    setFormInitialData({});
    getTeamManagementData();
    setShowModal(false);
    handleSaveAnimation();
    searchInput.current.value = '';
    setSelectedDropdown(' ');
  };

  function getCtaRoute(inspectionStatus, dueDate, projectId) {
    if (inspectionStatus === "scheduled" && moment().format("MM-DD-YYYY") === dueDate) {
      return `/projects/${projectId}/start-inspection`;
    }
  
    return `/projects/${projectId}/inspections`
  }

  function getCtaText(dueDate, inspectionStatus) {
    const formattedTodayDate = new Date()
    const formattedDueDate =  new Date(dueDate.replace(/-/g,'/'))
  
    if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime() && inspectionStatus === "scheduled") {
      return "Start Inspection";
    }

    if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime() && inspectionStatus === "new") {
      return "Finish";
    }
  
    if (formattedTodayDate > formattedDueDate) {
      return "Past Due";
    }
  
    return "View Project";
  }

  const handleButtonColors = (dueDate, inspectionStatus) => {
    if(getCtaText(dueDate, inspectionStatus) === "View Project")
      return "view-project-button action-btn"
     
    if(getCtaText(dueDate, inspectionStatus) === "Past Due")
      return "past-due-color action-btn"
    
    if(getCtaText(dueDate, inspectionStatus) === "Finish")
    return "list-view-finish-project-color action-btn"

    return "start-inspection-color action-btn"
  }

  return (
    <>
      <div className='team-management' ref={widgetRef}>
        <header className='team-management__header'>
          <h2 className='team-management__title'>Team Management</h2>
          <div className='team-management__bars'>
            <select
              name='finding-type'
              id='finding-type'
              placeholder='Select'
              onChange={handleDropDownChange}
              value={selectedDropdown}
            >
              <option value=' '>All User's</option>
              {dropDownValues.map((ele) => (
                <option value={ele} key={ele}>
                  {ele}
                </option>
              ))}
            </select>
            <input
              ref={searchInput}
              onChange={handleSearchChange}
              disabled={isLoadingForTM}
              placeholder='Search'
            />
          </div>
          {/* <div className='team-management__ellipsis-button'>
            <span onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div> */}
          <EllipsisPopover
            handleCloseFunc={handleClose}
            id={id}
            open={open}
            anchorEl={anchorEl}
          />
        </header>

        <div
          data-rbd-drag-handle-context-id={providedProps}
          data-rbd-drag-handle-draggable-id='gibberish'
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: 'auto',
          }}
        >
          <div className='team-management__body'>
            <div className='team-management__content'>
              <div className='team-management__table-head'>
                <Typography
                  variant='body1'
                  color='#6D747E'
                  className='header-text'
                >
                  Team Member
                </Typography>
                <Button
                  variant='contained'
                  color='success'
                  className='reassign-now-btn'
                  onClick={handleReassignClick}
                >
                  Re-assign
                </Button>
              </div>
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
                component='nav'
              >
                {!isLoadingForTM ? (
                  filteredData.length > 0 ? (
                    filteredData.map((elm) => {
                      return  <React.Fragment key={elm.id}>
                      <ListItemButton
                        onClick={(e) => handleClick(elm.id)}
                        sx={{
                          height: '53px',
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemIcon>
                          {elm.isOpen ? (
                            <FontAwesomeIcon
                              icon={faChevronCircleDown}
                              className='collapseIcon'
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronCircleRight}
                              className='collapseIcon'
                            />
                          )}
                        </ListItemIcon>
                        <Typography variant='body1' color='#6D747E'>
                          {elm.name}
                        </Typography>
                      </ListItemButton>
                      <Collapse in={elm.isOpen} timeout='auto' unmountOnExit>
                        <List component='span'>
                          {elm.inspections && elm.inspections.length ? (
                            <Table
                              aria-label='Inspection Table '
                              padding='checkbox'
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding='checkbox'
                                    align='left'
                                    sx={{
                                      maxWidth: '25px',
                                      minWidth: '25px',
                                    }}
                                  >
                                    <Checkbox
                                      onChange={(e) =>
                                        handleSelectAll(elm.id)
                                      }
                                      icon={
                                        <FontAwesomeIcon
                                          icon={faCheckCircleRegular}
                                          size={'lg'}
                                          className={'checkboxDeactive'}
                                        />
                                      }
                                      checkedIcon={
                                        <FontAwesomeIcon
                                          icon={faCheckCircleSolid}
                                          size={'lg'}
                                          className={'checkboxActive'}
                                        />
                                      }
                                      checked={Boolean(elm.checked)}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>Site Name</StyledTableCell>
                                  <StyledTableCell>
                                    Client Name
                                  </StyledTableCell>
                                  <StyledTableCell align='center'>
                                    Due Date
                                  </StyledTableCell>
                                  <StyledTableCell align='center'>
                                    Action
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {elm.inspections.map((ins) => (
                                  <TableRow key={ins.projectId} hover={true} className='team-management__body-data'>
                                    <StyledTableCell
                                      align={'left'}
                                      sx={{
                                        maxWidth: '25px',
                                        minWidth: '25px',
                                      }}
                                    >
                                      <Checkbox
                                        onChange={(e) =>
                                          handleToggle(ins.projectId)
                                        }
                                        icon={
                                          <FontAwesomeIcon
                                            icon={faCheckCircleRegular}
                                            size={'lg'}
                                            className={'checkboxDeactive'}
                                          />
                                        }
                                        checkedIcon={
                                          <FontAwesomeIcon
                                            icon={faCheckCircleSolid}
                                            size={'lg'}
                                            className={'checkboxActive'}
                                          />
                                        }
                                        checked={Boolean(ins.checked)}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        maxWidth: '60px',
                                        minWidth: '60px',
                                      }}
                                    >
                                      <Typography className='Table-Cell-Text'>
                                        {ins.siteName}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        maxWidth: '60px',
                                        minWidth: '60px',
                                      }}
                                    >
                                      <Typography className='Table-Cell-Text'>
                                        {ins.clientName}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                      {ins.dueDate}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align='center'
                                      sx={{
                                        maxWidth: '60px',
                                        minWidth: '60px',
                                      }}
                                    >
                                      <button className={classNames(handleButtonColors(ins.dueDate, ins.inspectionStatus))}>
                                        <Link to={getCtaRoute(ins.inspectionStatus, ins.dueDate, ins.projectId)}>
                                          <span>{getCtaText(ins.dueDate, ins.inspectionStatus)}</span>
                                        </Link>
                                        </button>
                                    </StyledTableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            <Typography
                              variant='body1'
                              sx={{ textAlign: 'center' }}
                              color='#6D747E'
                            >
                              No Inspections Found
                            </Typography>
                          )}
                        </List>
                      </Collapse>
                    </React.Fragment>
                    })
                  ) : (
                    <Typography
                      variant='body1'
                      sx={{ textAlign: 'center' }}
                      color='#6D747E'
                    >
                      No Team Members Found
                    </Typography>
                  )
                ) : (
                  <Loading what='data' />
                )}
              </List>
            </div>

            <footer className='team-management__footer'>
              <span onClick={changeShowMore}>
                Show {mdSize === 6 ? 'More' : 'Less'}{' '}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </span>
            </footer>
          </div>
        </div>
      </div>
      {showModal && (
        <ReassignModal
          initialId={formInitialData.inspectorId}
          projectIds={formInitialData.projectIds}
          clientIds={formInitialData.clientIds}
          show={showModal}
          isV2={true}
          hideModal={() => setShowModal(false)}
          handleReassign={handleReassign}
          users={teamManagementData.team.map((inspector) => ({
            value: inspector.id,
            label: inspector.name,
          }))}
        />
      )}
      <SuccessNotification show={showSuccess} />
    </>
  );
};

export default TeamManagement;
