import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

//context
import DashboardContext from '../../contexts/DashboardContext';

//importing widgets
import CertificationsDue from './widgets/CertificationsDue/CertificationsDue';
import FindingsOverview from './widgets/FindingsOverview/FindingsOverview';
import OpenFindings from './widgets/OpenFindings/OpenFindings';
import TeamManagement from './widgets/TeamManagement/TeamManagement';
import InspectionsDue from './widgets/InspectionsDue/InspectionsDue';
import Calendar from './widgets/Calendar/Calendar';
import Precipitation from './widgets/Precipitation'

type Props = {
    provide: any;
    ele: any;
    index: number;
}

const GridItem = (props: Props) => {

    const { dashboardWidgetSizeHolder, setDashboardWidgetSizeHolder } = useContext(DashboardContext);

    const [mdSize, setMdSize] = useState(dashboardWidgetSizeHolder[props.ele] || 6);

    const callComponent = (ele: String, provided: any, changeShowMore: Function) => {
        switch (ele) {
            case 'Findings Overview':
                return <FindingsOverview providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'Certifications Due':
                return <CertificationsDue providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'Open Findings':
                return <OpenFindings providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'Team Management':
                return <TeamManagement providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'My Projects':
                return <InspectionsDue providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'Calendar':
                return <Calendar providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
            case 'Precipitation':
                return <Precipitation providedProps={provided.dragHandleProps && provided.dragHandleProps["data-rbd-drag-handle-context-id"]} changeShowMore={changeShowMore} mdSize={mdSize} />;
    
            default:
                return null;
        }
    };

    const changeShowMore = () => {
        let tempArr = dashboardWidgetSizeHolder;

        if (tempArr[props.ele] === 6) {
            tempArr[props.ele] = 12;
        } else {
            tempArr[props.ele] = 6;
        }
        setMdSize(tempArr[props.ele])
        setDashboardWidgetSizeHolder(tempArr);
        localStorage.setItem("widgetSizeHolder", JSON.stringify(tempArr))
    }

    useEffect(()=>{ 
        let tempHolder:any

        if(localStorage.getItem("widgetSizeHolder")){
            tempHolder = localStorage.getItem("widgetSizeHolder")
            tempHolder = JSON.parse(tempHolder)
            setDashboardWidgetSizeHolder(tempHolder)
            setMdSize(tempHolder[props.ele])
        }
        else{
            setMdSize(6)
        }

    },[localStorage.getItem("widgetSizeHolder")])

    return (
        <Grid item xs={12} md={mdSize}>
            <li
                ref={props.provide.innerRef}
                {...props.provide.draggableProps}
                {...props.provide.dragHandleProps}
            >
                <p>{callComponent(props.ele, props.provide, changeShowMore)}</p>
            </li>
        </Grid>
    )
}

export default GridItem