import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";

import AuthenticationContext from "../../contexts/AuthenticationContext";

function Logout() {
  const history = useHistory();
  const authStore = useContext(AuthenticationContext);

  useEffect(() => {
    window.location.reload()
    authStore.logout();
    history.push("/login");
  }, []);

  return <Redirect push to="/login" />;
}

export default Logout;
