import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useCallback } from 'react';
// import useDivisionResource from '../../../hooks/divisionResource';
import legendItemApi from '../../../utils/api/legendItem';
import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import FormModal from '../../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import DivisionApi from '../../../utils/api/division';
import { useLocation } from "react-router-dom";


import useResource from '../../../hooks/resource';

const getSchema = (formData) => {

  const regulationIds = formData.regulations;
  const divisions = formData.divisions;

  const sch = {
    divisionId: {
      label: 'Division',
      controlType: UIControlType.select,
      options: divisions,
      labelKey: 'name',
      valueKey: 'id',
      validation: {
        required: true,
      },
      parse: (val) => Number.parseInt(val, 10),
    },
    regulationIds: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showClearAll: true,
      label: 'Regulations',
      options: regulationIds || [],
      noOptionMessage: 'No regulations found',
      placeholder: 'Search for regulations',
      validation: {
        required: true,
      },
    },
  };

  return sch;
};

const handleSubmit = (formData, callback) => {
  return legendItemApi
    .copy(
      formData.divisionId,
      formData.regulationIds,
      formData.selectedItems,
    )
    .then(callback);
};

function LICopyFormUI({
  onCancel,
  onSubmit,
  title,
  cancelText,
  showSubmit,
  initialValue,
  formData,
  setFormData,
}) {
  const formContext = useContext(FormContext);

  const setRegulations = useCallback(
    (divisionId) => {
      DivisionApi.regulations
        .index(divisionId)
        .then((data) => {
          setFormData({
            ...formData,
            regulations: data.length ? data : [],
          });

          if (
            formContext.value.regulationIds &&
            formContext.value.regulationIds.length
          ) {
            formContext.set(
              'regulationIds',
              formContext.value.regulationIds.filter((c) =>
                data.map((_) => _.id).includes(c.id),
              ),
            );
          } else {
            formContext.set('regulationIds', []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [formContext.value.divisionId],
  );

  useEffect(() => {
    if (formContext.value.divisionId) {
      setRegulations(formContext.value.divisionId);
    }
  }, [formContext.value.divisionId]);

  return (
    <FormModal
      modalProps={{
        title,
        isSmall: false,
      }}
      onCancel={onCancel}
      // eslint-disable-next-line no-shadow
      onSubmit={(formData) => {
        return handleSubmit(formContext.value, onSubmit);
      }}
      showSubmit={showSubmit}
      cancelText={cancelText}
    >
      <div className='legend-item-form'>
        <FormSchemaFields
          onChange={formContext.set}
          formData={formContext.value}
          schema={getSchema(formData)}
        />
      </div>
    </FormModal>
  );
}

export default function LICopyForm({ selectedItems, ...props }) {
  const [initialValue] = useState({
    regulationIds: [],
    divisionId: '',
    selectedItems: selectedItems,
  });

  const appDivisionContext = useContext(AppDivisionContext);


  const location = useLocation();
  
  if (location.search) {
    const params = new URLSearchParams(location.search);
    
    params.delete('search');
    location.search = params.toString();
  }

  const formDataQuery =
    appDivisionContext.appDivisionId &&
    useResource({
      resource: 'divisions',
      disablePagination: true,
      divisionId: appDivisionContext.appDivisionId,
    });
  

  const [loaded, setLoaded] = useState(false);

  // form options for selection of clients/roles/projects
  const [formData, setFormData] = useState({
    divisions: formDataQuery.data,
  });

  useEffect(() => {
    if (!loaded && !formDataQuery.loading) {
      setFormData({
        divisions: formDataQuery.data,
      });
      setLoaded(true);
    }
  }, [formDataQuery]);

  // only render when loaded and initial value is set
  if (!loaded || !initialValue) {
    return null;
  }

  return (
    <FormContextProvider initialValue={initialValue} key={'LiCopyFormUI'}>
      <LICopyFormUI
        initialValue={initialValue}
        formData={formData}
        setFormData={setFormData}
        title={'Copy Legends'}
        {...props}
      />
    </FormContextProvider>
  );
}

LICopyForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  showSubmit: PropTypes.bool,
};

LICopyForm.defaultProps = {
  readOnly: false,
  showSubmit: true,
  enableDelete: false,
};
