import { Inspection } from '@sw-sw/lib-inspection-templates';
import xhrService from '../xhrService';
import { Finding } from './finding';
const baseUrl = '/api/projects';

export type ProjectInspection = Omit<Inspection, 'id'> & {
  id: number;
  findings: Array<Finding>;
  pageNumber?: string | number | null;
  pageSize?: number,
};

export const projectInspectionApi = {
  index: (id: number, pageNumber?: string | number | null, pageSize?: number
  ): any => {
    const params: {
      p?: string | number | null, pageSize?: number
    } = {};

    if (pageNumber) {
      params.p = pageNumber;
    }

    if (pageSize) {
      params.pageSize = pageSize;
    }

    return xhrService
      .get(`${baseUrl}/${id}/inspections`, { params })
      .then((response) => response.data);
  },
  indexIds: (id: number): any => {

    return xhrService
      .get(`${baseUrl}/${id}/inspections/Ids`)
      .then((response) => response.data);
  },
};


export default projectInspectionApi;
