import React, { useEffect, useContext, useCallback } from "react";
import useMap from "../../useMap";
import ContextMenuInteractionReadOnly, { extractMapEventFeature } from "./ContextMenu/MapContextMenuReadOnly";
import { getUtil } from "../../../../utils/positionable";
import { Context as InteractionContext } from "../InteractionContext";
import { Context as SelectionContext } from "../Select/SelectFeature";
import DataSourceContext from "../../DataSourceContext";

function FeatureDetails({ readOnly = false }) {
  const map = useMap();
  const interactionContext = useContext(InteractionContext);

  const selectionContext = useContext(SelectionContext);
  const dataSourceContext = useContext(DataSourceContext);
  const {
    setMode,
    setPositionableSourceModel,
  } = useContext(InteractionContext);

  const getProjectUploadOptions = useCallback(
    (event, contextmenuFeature) => {
      const geometryType = contextmenuFeature && contextmenuFeature.get("geometry").getType();

      return (contextmenuFeature && contextmenuFeature.values_ && (contextmenuFeature.values_.positionable_type === "control_measure" || contextmenuFeature.values_.positionable_type === "pollutant"))
        ? [
            {
              label: geometryType === "Point" ? "Move" : "Edit",
              onClick: () => {
                const feature = selectionContext.getSelectedFeature();

                if (feature) {
                  setPositionableSourceModel(null);
                  setMode("modify", { feature });
                }
              },
            },
            {
              label: "Remove from Map",
              onClick: event => {
                let feature = selectionContext.getSelectedFeature();

                if (!feature && event.feature) {
                  feature = event.feature;
                }

                if (feature) {
                  setMode("delete", {
                    feature,
                  });
                }
              },
            },
          ] : []
    },
    [dataSourceContext, selectionContext],
  );

  const handleClick = useCallback(
    event => {
      const feature = extractMapEventFeature(event, map);

      let util;

      if (feature) {
        util = getUtil(feature.get("positionable_type"));
      }

      /**
       * Show overlay only if clicked on a feature
       * and overlay is supported by feature.
       *
       * If no feature clicked, close overlay, if active
       */
      if (feature && util && util.hasOverlay()) {
        interactionContext.setMode("detail", {
          feature,
          readOnly,
        });
      } else {
        interactionContext.setDefaultMode();
      }
    },
    [interactionContext],
  );

  useEffect(() => {
    map.on("singleclick", handleClick);

    return () => {
      map.un("singleclick", handleClick);
    };
  }, []);

  if(readOnly){
    return <ContextMenuInteractionReadOnly getProjectUploadOptions={getProjectUploadOptions} readOnly={readOnly}/>
  }

  return <div />;
}

export default FeatureDetails;
