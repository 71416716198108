import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import FormModal from '../../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import useResource from '../../../hooks/resource';
import inspectionTemplateApi from '../../../utils/api/inspectionTemplate';
import { useLocation } from "react-router-dom";


const getSchema = (formData, role) => {
  const divisionIds = formData.divisionIds;

  const sch = {
    divisionIds: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showClearAll: true,
      label: 'Divisions',
      options: divisionIds || [],
      noOptionMessage: 'No divisions found',
      placeholder: 'Search for divisions',
      validation: {
        required: true,
      },
    },
  };

  return sch;
};

const handleSubmit = (formData, callback) => {
  return inspectionTemplateApi
    .copy(formData.divisionIds, formData.selectedItems)
    .then(callback);
};

function InspectionTemplateCopyUI({
  onCancel,
  onSubmit,
  title,
  cancelText,
  showSubmit,
  initialValue,
  formData,
  setFormData,
  role,
}) {
  const formContext = useContext(FormContext);

  return (
    <FormModal
      modalProps={{
        title,
        isSmall: false,
      }}
      onCancel={onCancel}
      // eslint-disable-next-line no-shadow
      onSubmit={(formData) => {
        return handleSubmit(formContext.value, onSubmit);
      }}
      showSubmit={showSubmit}
      cancelText={cancelText}
    >
      <div className='legend-item-form'>
        <FormSchemaFields
          onChange={formContext.set}
          formData={formContext.value}
          schema={getSchema(formData, role)}
        />
      </div>
    </FormModal>
  );
}

export default function InspectionTemplateCopy({ selectedItems, ...props }) {
  const [initialValue] = useState({
    divisionIds: [],
    selectedItems: selectedItems,
  });

  const appDivisionContext = useContext(AppDivisionContext);
  
  const location = useLocation();
  
  if (location.search) {
    const params = new URLSearchParams(location.search);
    
    params.delete('search');
    location.search = params.toString();
  }


  const formDataQuery =
    appDivisionContext.appDivisionId &&
    useResource({
      resource: 'divisions',
      disablePagination: true,
      divisionId: appDivisionContext.appDivisionId,
    });

  const [loaded, setLoaded] = useState(false);

  // form options for selection of clients/roles/projects
  const [formData, setFormData] = useState({
    divisionIds: formDataQuery.data,
  });

  useEffect(() => {
    if (!loaded && !formDataQuery.loading) {
      setFormData({
        divisionIds: formDataQuery.data,
      });
      setLoaded(true);
    }
  }, [formDataQuery]);

  // only render when loaded and initial value is set
  if (!loaded || !initialValue) {
    return null;
  }

  return (
    <FormContextProvider initialValue={initialValue} key={'LiCopyFormUI'}>
      <InspectionTemplateCopyUI
        initialValue={initialValue}
        formData={formData}
        setFormData={setFormData}
        title={'Copy Inspection Templates'}
        {...props}
      />
    </FormContextProvider>
  );
}

InspectionTemplateCopy.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  showSubmit: PropTypes.bool,
};

InspectionTemplateCopy.defaultProps = {
  readOnly: false,
  showSubmit: true,
  enableDelete: false,
};
