import { useContext } from "react";
import { useQuery } from "react-query";
import AppContext from "../contexts/AppContext";
// import { correctiveActionsFindingsApi } from "../utils/api/correctiveActionsFindingsApi";
import legendItemApi from "../utils/api/legendItem";
import projectApi from "../utils/api/project";
import uploadApi from "../utils/api/upload";

export function useProjectContacts(projectId: number) {
  const appContext = useContext(AppContext);

  return useQuery(["projectContacts", projectId], () =>
    appContext.loadData(() => projectApi.contacts.index(projectId), "Contacts"),
  );
}

// export function useProjectCorrectiveActions(projectId: number, filter: string, page: number ) {
//   const appContext = useContext(AppContext);

//   return useQuery(["projectCorrectiveActions", projectId], () =>
//     appContext.loadData(
//       () => correctiveActionsFindingsApi.index(projectId, filter, page),
//       "Corrective Actions",
//     ),
//   );
// }

export function useProjectBmpDocuments(projectId: number, groupId: number) {
  const appContext = useContext(AppContext);

  return useQuery(
    ["bmpDocuments", projectId],
    () =>
      appContext.loadData(
        () =>
          legendItemApi
            .indexByProjectUsage(projectId)
            .then(controls => {
              const controlIds = controls.map(
                (control: { id: number }) => control.id,
              );

              return uploadApi.groupControl
                .indexByGroup(groupId, controlIds)
                .then(docs => ({ controls, docs }));
            })
            .then(({ controls, docs }) => {
              return controls.map((bmp: any) => {
                return {
                  ...bmp,
                  group_docs: docs
                    .map((d: any) => ({ ...d, projectId }))
                    .filter(
                      (doc: { legend_item_id: number }) =>
                        doc.legend_item_id === bmp.id,
                    ),
                };
              });
            }),
        "BMP Details",
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProjectLog(projectId: number) {
  const appContext = useContext(AppContext);

  return useQuery(["projectLog", projectId], () =>
    appContext.loadData(() => {
      return projectApi.getLog(projectId);
    }, "Project Log"),
  );
}
